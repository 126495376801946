<template>
  <div class="search">
    <div class="search-all">
      <div class="search-box flex-between">
        <div class="left" @click="$router.go(-1)"></div>
        <div class="center ellipsis">博主热榜</div>
        <div class="right"></div>
      </div>
      <div class="section">
        <div class="user flex-between" v-if="videoList.length > 0">
          <router-link
            class="user-left flex"
            tag="div"
            :to="{
              path: '/bloggerDetails',
              query: { id: videoList[0].author.id },
            }"
          >
            <ImgDecypt
              class="ad-img"
              :round="true"
              :src="videoList[0].author.avatarUrl"
            />
            <div class="user-info">
              <div class="title">{{ videoList[0].author.nickName }}</div>
              <div class="fans">
                粉丝 {{ videoList[0].author.fanss }}&ensp;获赞
                {{ videoList[0].author.likes }}
              </div>
            </div>
          </router-link>
          <div
            class="user-right"
            :class="{ cared: videoList[0].author.cared }"
            @click="userCare(videoList[0].author.cared)"
          >
            {{ videoList[0].author.cared ? "已关注" : "关注" }}
          </div>
        </div>
        <pullrefresh
          :disabled="true"
          @onLoad="onLoad"
          ref="pull"
          v-show="videoList.length > 0"
        >
          <div v-for="(item,index) in videoList" :key="item.id">
            <videoListsec :item="item" />
            <AdvSwiper
              :advList="listAd"
              v-if="(index + 1) % 4 == 0 && listAd.length > 0"
              class="l_adv"
            />
          </div>
        </pullrefresh>
        <van-loading
          type="spinner"
          color="#f21313"
          v-show="videoList && videoList.length == 0 && !noData"
        />
        <nodata v-show="noData" />
      </div>
    </div>
    <!-- <div class="backTop" v-if="scrollTop > 300" @click="backTop"></div> -->
  </div>
</template>
<script>
import videoListsec from "../widget/videoListsec.vue";
import pullrefresh from "@/components/PullRefresh/index.vue";
import { getUserList, userCare } from "@/api/app.js";
import { getAdItem, AdType } from "@/utils/getAdv.js";
import AdvSwiper from "@/components/AdvSwiper/index.vue";
export default {
  name: "userMoreVideo",
  components: {
    videoListsec,
    pullrefresh,
    AdvSwiper,
  },
  data() {
    return {
      videoList: [], //视频列表
      pageNum: 1, //页码
      noData: false, //暂无数据
      listAd: [],
      scrollTop: 0,
    };
  },
  // watch: {
  //   $route(to) {
  //     window.removeEventListener('scroll', this.scrollToTop, true)
  //     if (to.path == "/userMoreVideo") {
  //       window.addEventListener('scroll', this.scrollToTop, true)
  //     }
  //   },
  // },
  created() {
    this.listAd = getAdItem(AdType.columnAd);
    this.getList();
  },
  activated() {
    // console.log(999)
  },
  mounted () {
    window.addEventListener('scroll', this.scrollToTop, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop, true)
  },
  methods: {
    async userCare(care) {
      //关注或取消关注
      let req = {
        isCare: !care, //true为关注，false为取消关注
        userId: Number(this.$route.query.id),
      };
      let ret = await this.$Api(userCare, req);
      if (ret.code == 200) {
        if (!care) {
          this.$toast("关注成功");
          this.videoList[0].author.cared = true;
        } else {
          this.videoList[0].author.cared = false;
        }
      }
    },
    async getList() {
      //通过接口得到某个博主的视频列表
      let req = {
        isVip: true,
        pageNum: this.pageNum,
        pageSize: 10,
        umt: 1, //1：原创 2:动态视频 3：粉丝福利 4:动态帖子 0:无效的占位符
        userID: Number(this.$route.query.id),
      };
      let ret = await this.$Api(getUserList, req);
      if (!this.$refs.pull) return;
      this.$refs.pull.loading = false;
      if (ret.code == 200) {
        let list = ret.data.mediaInfos ? ret.data.mediaInfos : [];
        this.videoList = this.videoList.concat(list);
        if (list.length < 10) {
          this.$refs.pull.finished = true;
        } else {
          this.$refs.pull.finished = false;
        }
        if (this.pageNum == 1 && list.length == 0) {
          this.noData = true;
        }
      }
    },
    onLoad() {
      this.pageNum++;
      this.getList();
    },
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    // backTop () {
    //     let dom = document.querySelector(".section");
    //     let timer = setInterval(() => {
    //       let ispeed = Math.floor(-this.scrollTop / 5)
    //       dom.scrollTop = dom.scrollTop = this.scrollTop + ispeed
    //       if (this.scrollTop === 0) {
    //         clearInterval(timer)
    //       }
    //     }, 16)
    // },
    scrollToTop () {
      let dom = document.querySelector(".section");
      this.scrollTop = dom.scrollTop
    }
  },
};
</script>
<style lang="scss" scoped>
.search-all {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.search-box {
  padding: 0 16px;
  height: 52px;
  align-items: center;
  background: $vermillion;
}
.l_adv{
  height: 100px;
  overflow: hidden;
  padding-top: 10px;
  /deep/ .swiper-container,/deep/ .swiperSlide{
    height: 100%;
  }
}
.left {
  width: 22px;
  height: 22px;
  background: url("../../assets/png/back_icon.png") center center no-repeat;
  background-size: 100%;
}
.center {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}
.right {
  width: 22px;
  height: 30px;
}
.section {
  height: calc(100% - 52px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 10px 10px 30px;
  box-sizing: border-box;
}
.user {
  align-items: center;
  padding: 6px 6px 10px;
}
.ad-img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #f21313;
  margin-right: 10px;
}
.title {
  font-size: 17px;
  margin-bottom: 3px;
}
.user-right {
  color: #ffffff;
  font-size: 16px;
  padding: 6px 16px;
  background: $vermillion;
  border-radius: 18px;
}
.cared {
  background: #e1e0e1;
}
.backTop {
  position: fixed;
  right: 8%;
  bottom: 10%;
  width: 50px;
  height: 50px;
  background: url("../../assets/png/backTop.png");
  background-size: 100% 100%;
  z-index: 3;
}
</style>
